import React from 'react';
import {Page} from '../components/Page';
import {SplitPage} from '../components/SplitPage';
import {graphql} from 'gatsby';
import {SplitPageMain} from '../components/SplitPageMain';
import {SplitPageSide} from '../components/SplitPageSide';
import {Markdown} from '../components/Markdown';
import {Helmet} from "react-helmet";
import PageHeader from '../components/PageHeader';

export default function Component({data: {contactDetails}}) {
	return <Page>
		<Helmet>
{/*			<link rel="canonical" href="https://www.icit.com.au/contact" />*/}
			<title>ICIT - Contact us</title>
		</Helmet>
		<PageHeader>
			{contactDetails.title}
		</PageHeader>
		<SplitPage>
			<SplitPageMain>
				<div>
					<header>
						<h3>
							Business hours
						</h3>
					</header>
					<Markdown>{contactDetails.businessHours}</Markdown>
				</div>
				<div>
					<Markdown>{contactDetails.email}</Markdown>
				</div>
				<div>
					<a href={contactDetails.termsConditions.localFile.publicURL} target="_blank">Terms and Conditions</a>
				</div>
			</SplitPageMain>
			<SplitPageSide>
				<div>
					<header>
						<h3>
							Phone
						</h3>
					</header>
					<div>
						<div>{contactDetails.phone}</div>
					</div>
				</div>
				<div>
					<header>
						<h3>
							Postal address
						</h3>
					</header>
					<div>
						<div>{contactDetails.postalAddress}</div>
					</div>
				</div>
				<div>
					<header>
						<h3>
							ABN
						</h3>
					</header>
					<div>
						<div>{contactDetails.abn}</div>
					</div>
				</div>
			</SplitPageSide>
		</SplitPage>
	</Page>;
}

export const query = graphql`
    query {
        contactDetails {
        	title
        	businessHours
        	email
        	phone
        	postalAddress
        	abn
        	termsConditions {
        		localFile {
        			publicURL
        		}
        	}
        }
    }
`;
